.pointer {
  cursor: pointer;
}
.no-deco {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  text-shadow: none;
}
.no-deco:hover {
  text-decoration: none;
  color: inherit;
}
.modalDiv {
  width: 100vw;
  height: 100vh;
  padding: 50px;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  /* Safari */
  -moz-transition: all 0.3s;
  /* Safari */
  -ms-transition: all 0.3s;
  /* Safari */
  -o-transition: all 0.3s;
  /* Safari */
  background-color: rgba(91, 112, 131, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modalBox {
  width: 80vw;
  height: 80vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  background-color: white;
  border-radius: 5px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #248dbd;
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: transparent;
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background-color: #e7e8ea;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #8d9297;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.6;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  /* Safari */
  -moz-transition: all 0.3s;
  /* Safari */
  -ms-transition: all 0.3s;
  /* Safari */
  -o-transition: all 0.3s;
  /* Safari */
}
.nav-tabs .nav-link.active {
  border-bottom: 3px solid #248dbd;
  font-size: 18px;
  opacity: 1;
}
.nav-tabs .nav-link:hover {
  border-color: transparent;
  color: #248dbd;
  opacity: 1;
}
.nav-tabs > li > a {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.tab-pane {
  padding: 15px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.nav-item.dropdown.open .dropdown-menu {
  display: block;
}
.offcanvas-header {
  position: absolute;
  z-index: 5;
}
.offcanvas-body {
  padding: 0;
}
.offcanvas-close-button {
  background-color: #FFFFFF50;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 5;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.offcanvas-close-button:hover {
  background-color: #FFFFFF90;
}
.page-nav-pills {
  border-bottom: 1px solid #e7edf2;
  background-color: #e7e8ea;
  margin: 0;
  margin-bottom: 40px;
  display: flex;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
  scrollbar-width: none;
  overflow: auto hidden;
  width: 100%;
  flex-wrap: nowrap;
  position: relative;
}
.page-nav-pills::before {
  left: 0;
  content: '';
  background: linear-gradient(to left, rgba(231, 232, 234, 0), #e7e8ea 100%);
  opacity: 1;
  position: absolute;
  width: 15px;
  height: 42px;
  z-index: 3;
}
.page-nav-pills::after {
  right: 0px;
  content: '';
  background: linear-gradient(to right, rgba(231, 232, 234, 0), #e7e8ea 100%);
  opacity: 1;
  position: absolute;
  width: 30px;
  height: 42px;
}
.page-nav-pills.white-pills {
  background-color: white;
}
.page-nav-pills.white-pills::before {
  display: none;
}
.page-nav-pills.white-pills::after {
  display: none;
}
.page-nav-pills .nav-link {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: -1px;
  display: block;
  color: #101010;
  opacity: 0.6;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  /* Safari */
  -moz-transition: all 0.3s;
  /* Safari */
  -ms-transition: all 0.3s;
  /* Safari */
  -o-transition: all 0.3s;
  /* Safari */
  padding-left: 30px;
  padding-right: 30px;
}
.page-nav-pills .nav-link:hover {
  opacity: 0.1;
}
.page-nav-pills .nav-link.active {
  background-color: transparent;
  border-bottom: 3px solid #0891b2;
  color: #0891b2;
  font-weight: bold;
}
body {
  background-color: #f1f2f4;
}
.hello {
  font-weight: bold;
  color: red;
}
.swal2-styled.swal2-confirm {
  background-color: #0f7dae !important;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/src/Assets/fonts/Quicksand.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/src/Assets/fonts/Quicksand-300.ttf') format('woff');
  font-weight: light;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Quicksand';
  src: url('/src/Assets/fonts/Quicksand-700.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.logo-backdrop {
  background: radial-gradient(#59b1d9, #248dbd);
  display: block;
}
.fancybox__content {
  padding: 0!important;
}
a,
a:active,
a:checked,
a:focus,
a:visited {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  text-shadow: none;
}
a:hover,
a:active:hover,
a:checked:hover,
a:focus:hover,
a:visited:hover {
  text-decoration: none;
  color: inherit;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}
.rs-picker-toggle-textbox {
  border: 1px solid #ced4da !important;
}
.rs-picker-default .rs-picker-toggle {
  border: 1px solid #ced4da !important;
}
code {
  color: #0891b2 !important;
  font-family: 'Quicksand';
  font-weight: bold;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
.rs-drawer-body {
  padding: 10px;
  padding-top: 75px;
  padding-right: 0;
}
.ui.dropdown i.dropdown.icon {
  display: none !important;
}
.actions-dropdown {
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 500px;
}
@media only screen and (max-width: 769px) {
  div.container {
    padding: 0 !important;
  }
  .rs-drawer-wrapper {
    width: 100vw;
  }
  .rs-drawer-left.rs-drawer-sm,
  .rs-drawer-right.rs-drawer-sm {
    width: 100vw;
  }
  .rs-drawer-body {
    padding: 5px;
  }
  .rs-drawer-body-close {
    left: 15px;
    position: relative;
    top: 25px;
    margin-bottom: 50px;
  }
}
